@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './main.less';
@import './login.less';


#root {
  height: 100%;
}

  .ant-form-item-label {
    white-space: normal; /* 允许文本换行 */
    word-wrap: break-word;
  }